import axios, { AxiosInstance } from "axios";
import { Course, Lesson, Profile, Email } from "./Types";

export default class ApiClient {
  client: AxiosInstance;
  token : string;

  constructor(token:string) {
    this.token = token;
    this.client = axios.create({
      // get the base url from the environment variable
      baseURL: process.env.REACT_APP_GOLANG_API_URL,
      headers: {
        "Content-Type": "application/json",
        "Authorization":`Bearer ${token}`,
      },
    })
  }

  getUserProfile = async () => {
    const data = await this.client.get<Profile>("/profile");

    return data?.data;
  };

  getCourses = async () => {
    const data = await this.client.get<Course[]>("/courses");

    return data?.data;
  };

  getCourseData = async (courseId: number) => {
    const data = await this.client.get<Course>(
      `/course-data?course_id=${courseId}`
    );

    return data?.data;
  };


  sendTeamInvite = async (email: string, role: string) => {
    const data = await this.client.post(
      `/send-invite-email`, {Email: email, Role: role}
    );

    return data?.data;
  };

  markLessonViewed = async (courseId: number, lessonID: number) => {
    const data = await this.client.get<Lesson>(
      `/view-lesson?course_id=${courseId}&lesson_id=${lessonID}`
    );

    return data?.data;
  };
}
