import { useNavigate } from "react-router";
import { Course } from "../../Logic/Types";

export const CourseCardFooter = (props: { course: Course }) => {
  const { course } = props;
  const navigate = useNavigate();

  const completedLessons = course?.ordered_sections
    ?.flatMap((s) => s.ordered_lessons)
    .filter((l) => l.completed);

  const courseCompleted = completedLessons?.length === course.lesson_count;
  const courseStarted = completedLessons?.length > 0;

  let  courseProgress = "Not Started";
  let  progressClass = "course-progress-not-started";

  if(courseStarted) {
      if(courseCompleted) {
        progressClass = "course-progress-complete";
        courseProgress = "Completed";
      }
      else{
        progressClass = "course-progress-in-progress";
        courseProgress = "In Progress";
      } 
}

return (
    <div className="course-card-footer">
      <div className="course-lesson-count">{course.lesson_count} Lessons</div>
      {/* <div className={`course-progress-pill ${progressClass}`}>{courseProgress}</div> */}
      <button
        className="course-start-button"
        onClick={() => navigate(`/course/${course.id}`)}
      >
        Get Started
      </button>
    </div>
  );
};
