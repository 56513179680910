import { Course } from "../../Logic/Types";
import { CourseCardFooter } from "./CourseCardFooter";
import { useState } from "react";

export const CourseCard = ({ course }: { course: Course }) => {
  let wordCount = 27

  let initialDesc = course.description
  let initialArr = initialDesc.split(" ") 

  if (initialArr.length > wordCount){
    initialDesc = initialArr.slice(0, wordCount).join(" ") + "..."
  }

  const [description, setDescription] = useState(initialDesc);
  const [cardStyle, setCardStyle] = useState("course-card fixed-card");


  const showFullDescription = (e: any) => {
    let wordArr = course.description.split(" ")
    if (wordArr.length > wordCount){
      setDescription(course.description)
      setCardStyle("course-card dynamic-card")
    }
  }

  const hideFullDescription = (e: any) => {
    let wordArr = course.description.split(" ")
    if (wordArr.length >wordCount){
      setDescription(course.description.split(" ").slice(0, wordCount).join(" ") + "...")
      setCardStyle("course-card fixed-card")
    }
  }
  return (
    <div className={cardStyle} key={course.id} onMouseEnter ={(e) => showFullDescription(e)} onMouseLeave ={(e) => hideFullDescription(e)}>
      <img
        className="course-image"
        src={course.photo_url}
        alt="course image"
      />
      <div className="course-card-title">{course.name}</div>
      <div className="course-card-description">{description}</div>
      <CourseCardFooter course={course} />
    </div>
  );
};
