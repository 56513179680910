import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { UseCurrentCourse } from "../../Logic/Helpers";
import { CongratsCard } from "./CongratsCard";
import "./CoursePage.css";
import { CourseSidebar } from "./CourseSidebar";
import { SectionCard } from "./SectionCard";

export const CoursePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");
  const finished = searchParams.get("finished");

  let parsedSectionId = parseInt(section || "", 10);
  let nextSectionId = undefined;
  let previousSectionId = undefined;

  const { parsedCourseId, courseData } = UseCurrentCourse();

  // If the user navigates to /course without a courseId, redirect them to the home page
  if (!parsedCourseId) navigate("/");

  if (courseData && courseData.ordered_sections) {
    if (!section) {
      // If the user navigates to /course/:courseId without a section, use the first section
      parsedSectionId = courseData.ordered_sections[0].id;
    }

    let currentSectionIndex = courseData.ordered_sections.findIndex(
      (s) => s.id === parsedSectionId
    );
    if (currentSectionIndex === courseData.ordered_sections.length - 1) {
      nextSectionId = undefined;
    } else {
      nextSectionId = courseData.ordered_sections[currentSectionIndex + 1].id;
    }
    if (currentSectionIndex === 0) {
      previousSectionId = undefined;
    } else {
      previousSectionId =
        courseData.ordered_sections[currentSectionIndex - 1].id;
    }
  }

  

  return (
    <>
      <CourseSidebar currentSection={parsedSectionId} />
      
      <div className="course-page">
        {finished ? (
          <CongratsCard />
        ) : (
          <SectionCard
            section={courseData?.ordered_sections?.find(
              (s) => s.id === parsedSectionId
            )}
            courseId={parsedCourseId}
            nextSectionId={nextSectionId}
            previousSectionId={previousSectionId}
          />
        )}
      </div>


    </>
  );
};
