import { useQuery } from "react-query";
import { useContext } from "react";
import { ApiContext } from "../../Logic/ApiContextProvider";
const help = require("../../images/question.svg").default as string;
const feedback = require("../../images/star.svg").default as string;
const contact = require("../../images/envelope-closed.svg").default as string;

export const HomeSidebar = () => {
  const { apiClient } = useContext(ApiContext);

  const { data: userProfileData } = useQuery(
    "user",
    () => apiClient?.getUserProfile() || undefined,
    { enabled: !!apiClient?.token }
  );

  return (
    <nav className="left-navbar main-left-nav-width">
      <div className="left-nav-options">
        {!userProfileData ? (
          <div></div>
        ) : (
          <div className="organization-container">
            <img
              id="organization-logo"
              alt="organization logo"
              className="user-avatar  organization-image"
              src={userProfileData?.organization_photo}
            ></img>
            <div className="organization-info">
              <div className="organization-name">
                {userProfileData?.organization_name}
              </div>
              <div>{userProfileData?.member_count} Members</div>
              <div>{userProfileData?.project_count} Projects</div>
            </div>
          </div>
        )}
        <ul>
          <li>
            <a
              className="link-item"
              id="home-link-nav"
              href={process.env.REACT_APP_GOLANG_API_URL + "/home"}
            >
              <img className="leftnav-icon" src="/dashboard.png" />
              Dashboard
            </a>
          </li>
          <li>
            <a
              className="link-item"
              id="home-link-nav"
              href={process.env.REACT_APP_GOLANG_API_URL + "/project-templates"}
            >
              <img className="leftnav-icon" src="/people.png" />
              Engage Communities
            </a>
          </li>
          <li>
            <a
              className="link-item"
              id="home-link-nav"
              href={process.env.REACT_APP_GOLANG_API_URL + "/analyze-feedback"}
            >
              <img className="leftnav-icon" src="/charts.png" />
              Analyze Feedback
            </a>
          </li>
          <li>
          <a className="link-item" href="/">
              <img className="leftnav-icon" src="/lessons.png" />
              Watch Lessons
            </a>
          </li>
        </ul>
        <ul className="left-nav-footer">
          <li>
            <img className="leftnav-icon" src={contact} />
            <a
              className="footer-item"
              href="https://airtable.com/shrsekBQaFFCeGkPF"
              target="_blank"
              rel="noreferrer"
            >
              Contact Support
            </a>
          </li>
          <li>
            <img className="leftnav-icon" src={feedback} />
            <a
              className="footer-item"
              href="https://airtable.com/shrMM4WKE8hr6xCRh"
              target="_blank"
              rel="noreferrer"
            >
              Give Feedback
            </a>
          </li>
          <li>
            <a
              className="footer-item"
              id="help-center-link-nav"
              href="https://cocensus.notion.site/Help-Center-08177c0ba9ec4adf92ec191334146ddd"
              target="_blank"
              rel="noreferrer"
            >
              <img className="leftnav-icon" src={help} />
              Help Center
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};
