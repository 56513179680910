import { MutableRefObject, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useContext } from "react";
import { ApiContext } from "./ApiContextProvider";

export const UseCurrentCourse = () => {
  const { courseId } = useParams();
  const parsedCourseId = parseInt(courseId || "", 10);

  const { apiClient } = useContext(ApiContext);

  const { data: courseData } = useQuery(
    "course-" + parsedCourseId,
    () => apiClient?.getCourseData(parsedCourseId) || undefined,
    { enabled: !!apiClient?.token && !!parsedCourseId}
  );

  return { parsedCourseId, courseData };
};

// Hook
export function useOnScreen<T extends Element>(
  ref: MutableRefObject<T>,
  actionWhenOnScreen: () => void
): boolean {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  useEffect(() => {
    if (isIntersecting) {
      actionWhenOnScreen();
    }
  }, [isIntersecting, actionWhenOnScreen]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // Update our state when observer callback fires
      setIntersecting(entry.isIntersecting);
    }, {});
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}
