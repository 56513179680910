import { useContext } from "react";
import { useQuery } from "react-query";
import { ApiContext } from "../../Logic/ApiContextProvider";
import { CourseCard } from "./CourseCard";
import "./Home.css";
import { HomeSidebar } from "./HomeSidebar";
import "./Sidebar.css";

export const Home = () => {
  const title = "Community Engagement Lessons";

 
  const { apiClient } = useContext(ApiContext);

  const { data: courseData } = useQuery(
    "courses",
    () => apiClient?.getCourses() || undefined,
    { enabled: !!apiClient?.token}
  );

  return (
    <>
      <HomeSidebar />
      <div className="content" id="content">
        <div className="content-header">{title}</div>
        <div className="body">
          {!courseData ? (
              <div>Loading...</div>
            ) : (
              courseData.map((c) => {
                return <CourseCard course={c} key={c.id} />;
              })
            )}
        </div>  
      </div>
    </>
  );
};
