import { OverlayTrigger, Popover } from "react-bootstrap";
import { RxCaretDown } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router";
import { UseCurrentCourse } from "../Logic/Helpers";
import { User } from "@auth0/auth0-spa-js";
import { LogoutOptions } from "@auth0/auth0-react";
import { InviteMember } from "../Pages/CoursePage/InviteMember";
import { useState } from "react";
import React from "react";
import { Userpilot } from "userpilot";

const invite = require("../images/profile-plus.svg").default as string;

const logo = require("../images/cocensus_black.svg").default as string;
export const TopNav = (props: {
  user: User | undefined;
  logout: (options?: LogoutOptions | undefined) => void;
}) => {
  const navigate = useNavigate();
  const { courseData } = UseCurrentCourse();
  const title = courseData ? "Lessons > " + courseData.name : "Lessons";
  const [display, setDisplay] = useState(false);
  function changeDisplay() {
    setDisplay(true);
  }

  let location = useLocation();

  React.useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return (
    <div className="top-nav">
      <a className="sidebar-brand">
        <img
          className="top-nav-logo"
          src={require("../images/cocensus.png")}
          alt="logo"
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_GOLANG_API_URL}/home`)
          }
        />
      </a>
      <h1>{title}</h1>
      <div id="top-left-nav">
        <button id="invite-btn">
          <img className="invite-icon" src={invite} />
          <div onClick={changeDisplay}>Invite&nbsp;Members</div>
        </button>
        <InviteMember display={display} changeDisplay={setDisplay} />
        <UserMenu user={props.user} logout={props.logout} />
      </div>
    </div>
  );
};

const UserMenu = (props: {
  user: User | undefined;
  logout: (options?: LogoutOptions | undefined) => void;
}) => {
  const user = props.user;

  return (
    <OverlayTrigger
      trigger="click"
      placement={"bottom"}
      rootClose={true}
      overlay={
        <Popover className="user-menu">
          <div
            className="user-menu-item"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_GOLANG_API_URL}/account-settings`)
            }
          >
            <span>Account Settings</span>
          </div>
          <div
            className="user-menu-item"
            onClick={() =>
              (window.location.href =
                "https://calendly.com/co-census/sales?month=2023-03")
            }
          >
            <span>Contact Sales</span>
          </div>
          <div
            className="user-menu-item"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_HOME_SITE_URL}/upgrade-plan`)
            }
          >
            <span>Upgrade Your Plan</span>
          </div>
          <div
            className="user-menu-item"
            onClick={() => (window.location.href = "https://www.notion.so/cocensus/Help-Center-08177c0ba9ec4adf92ec191334146ddd")}
          >
            <span>Help</span>
          </div>
          <div
            className="user-menu-item"
            onClick={() => {
              props.logout({
                logoutParams: { returnTo: window.location.origin },
              });
            }}
          >
            <span>Log out</span>
          </div>
        </Popover>
      }
    >
      {!user ? (
        <div></div>
      ) : (
        <div className="user-info">
          <p>Hi {user?.name}</p>
          <img
            src={user?.picture}
            alt="user avatar"
            className="avatar user-avatar"
          />
          <i className="user-caret">
            <RxCaretDown />
          </i>
        </div>
      )}
    </OverlayTrigger>
  );
};
