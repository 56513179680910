import { useNavigate } from "react-router";
import { Section } from "../../Logic/Types";
import { CongratsCard } from "./CongratsCard";
import { LessonContent } from "./LessonContent";

export const SectionCard = ({
  section,
  courseId,
  nextSectionId,
  previousSectionId,
}: {
  section: Section | undefined;
  courseId: number;
  nextSectionId: number | undefined;
  previousSectionId: number | undefined;
}) => {
  const navigate = useNavigate();

  if (!section) return <div>Loading...</div>;

  return (
    <div className="section-card">
      {section.ordered_lessons.map((lesson) => {
        return (
          <LessonContent lesson={lesson} key={lesson.id} courseID={courseId} />
        );
      })}
      {previousSectionId && (
        <button
          className="previous-course-button"
          onClick={() =>
            navigate(`/course/${courseId}/?section=${previousSectionId}`)
          }
        >
          Previous
        </button>
      )}
      {nextSectionId ? (
        <button
          className="next-course-button"
          onClick={() =>
            navigate(`/course/${courseId}/?section=${nextSectionId}`)
          }
        >
          Next
        </button>
      ) : (
        <button
          className="next-course-button"
          onClick={() =>
            navigate(`/course/${courseId}/?section=${section.id}&finished=true`)
          }
        >
          FINISHED
        </button>
      )}
    </div>
  );
};
