import { useRef } from "react";
import { useContext } from "react";
import { useOnScreen } from "../../Logic/Helpers";
import { Lesson } from "../../Logic/Types";
import { ApiContext } from "../../Logic/ApiContextProvider";
// import { useQueryClient } from "react-query";

export const LessonContent = ({
  lesson,
  courseID,
}: {
  lesson: Lesson;
  courseID: number;
}) => {
  let content: JSX.Element;
  const { apiClient } = useContext(ApiContext);
  // const client = useQueryClient();
  const ref: any = useRef<HTMLDivElement>();
  useOnScreen(ref, () => {
    if (!lesson?.completed) {
      apiClient?.markLessonViewed(courseID, lesson.id).then(() => {
        // client.invalidateQueries(); //was causing infinite refetch
      });
    }
  });

  if (lesson.video_src) {
    content = <VideoEmbed video_src={lesson.video_src} />;
  } else if (lesson.links) {
    content = (
      <div className="lesson-content">
        {lesson.links.map((link) => {
          return (
            <div key={link.url}>
              <a href={link.url}>{link.title}</a>
            </div>
          );
        })}
      </div>
    );
  } else {
    content = <div className="lesson-content">Unknown lesson</div>;
  }

  return (
    <div className="lesson-content" ref={ref}>
      <p className="lesson-title">{lesson.title}</p>
      {content}
    </div>
  );
};

const VideoEmbed = ({ video_src }: { video_src: string }) => {
  return (
    <div style={{ position: "relative", paddingBottom: "62.5%", height: 0 }}>
      <iframe
        title="Loom video"
        src={`${video_src}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
        frameBorder="0"
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </div>
  );
};
