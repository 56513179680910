import { useState } from "react";
import { RxCaretDown, RxCaretRight } from "react-icons/rx";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { Lesson, Section } from "../../Logic/Types";
import { useContext } from "react";
import { ApiContext } from "../../Logic/ApiContextProvider";
import { UseCurrentCourse } from "../../Logic/Helpers";

export const CourseSidebar = ({
  currentSection: currentSectionId,
}: {
  currentSection: number;
}) => {
  const { courseData: course } = UseCurrentCourse();

  const { apiClient } = useContext(ApiContext);
  const { data: userProfileData } = useQuery(
    "user",
    () => apiClient?.getUserProfile() || undefined,
    { enabled: !!apiClient?.token }
  );

  if (!course) return <div></div>;

  const completedLessons = course.ordered_sections
  .flatMap((s) => s.ordered_lessons)
  .filter((l) => l?.completed);
  
  const currentSection = course.ordered_sections.find(c => c.id === currentSectionId)
  const allLessons = course.ordered_sections.flatMap((s) => s.ordered_lessons);

  return (
    <div className="sidebar course-sidebar">
      <div className="sidebar-header">
        <div className="organization-container">
          <img
            id="organization-logo"
            alt="organization logo"
            className="user-avatar   xxxxxxx organization-image"
            src={userProfileData?.organization_photo}
          ></img>
          <div className="organization-info">
            <div className="organization-name">
              {userProfileData?.organization_name}
            </div>
            <div>{userProfileData?.member_count} Members</div>
            <div>{userProfileData?.project_count} Projects</div>
          </div>
        </div>
      </div>
      <div className="lesson-outline">
        <h2>Number of lessons followed </h2>
        <h1>
          {currentSection?.section_number }/{course.lesson_count }
        </h1>
        <div className="section-list">
          {course.ordered_sections?.map((section) => {
            return (
              <SectionListing
                key={section.id}
                section={section}
                current={section.id === currentSectionId}
                allLessons={allLessons}
                lesson_at={course.lesson_at}
              />
            );
          })}
        </div>
      </div>
      <button id="return-courses" onClick={() => (window.location.href = "https://courses.cocensus.io/")}>Back to lessons page</button>
    </div>
  );
};

const SectionListing = ({
  section,
  current,
  allLessons,
  lesson_at,
}: {
  section: Section;
  current: boolean;
  allLessons: Lesson[];
  lesson_at: number;
}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(current);

  return (
    <div
      className={
        "section-listing" + (current ? " current-section-listing" : "")
      }
    >
      <div
        className="cursor-pointer "
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <RxCaretDown /> : <RxCaretRight />}
        <span className="bold">{section.section_number}. {section.description}</span>
      </div>
      {isExpanded &&
        section.ordered_lessons.map((lesson) => {
          let lessonDisabled = true;
          let lessonIndex = allLessons.indexOf(lesson);

          lessonDisabled = lessonIndex >= lesson_at;
          if (lessonDisabled) {
            return <p className="disabled-lesson">{lesson.title}</p>;
          }
          return (
            <p
              className="cursor-pointer"
              onClick={() => navigate(`/course/${1}?section=${section.id}`)}
              key={lesson.id}
            >
              {lesson.title}
            </p>
          );
        })}
    </div>
  );
};
