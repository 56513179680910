import { useNavigate } from "react-router";
import { ApiContext } from "../../Logic/ApiContextProvider";
import { useContext, useState } from "react";

export const InviteMember = (props: {display: boolean, changeDisplay: any}) => {
    if (!props.display){
        return null
    }

    function closeModal(event: any) {
        props.changeDisplay(false);
    }

    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const { apiClient } = useContext(ApiContext);

    function updateUser(type: string){
        if (error == "Please select a user type."){
            setError("")
        }
        setRole(type)
    }
    function sendInvite(event: any) {
        var error = document.getElementById("send-invite-error")
        if (error == null){
            return
        }
        
        if (role == ''){
            setError("Please select a user type.")
            return
        }

        apiClient?.sendTeamInvite(email, role).then((resp) => {
            setError("")
            setSuccess("Invite email sent to " + email)
        }).catch((resp)=>{
            setSuccess("")
            if(resp.response.status == 422){
                setError("Please invite a user with a valid work email ending in .com, .org, or .gov for security and quality support on co:census.")
            }else if (resp.response.status == 400){
                setError("Too many outgoing invitations, please revoke an invitation or purchase more license.")
            }else{
                setError("User is already invited")
            }
        })
    }
    return (
        <div>
            <div className="modal-backdrop"> </div>
            <div className="modal" id="invite-modal">
                <div className="modal-header">
                    <h5 className="modal-title" id="inviteModalLabel">Invite Team Members</h5>
                    <button type="button" id="close-invite-modal" className="close" onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    
                <div className="modal-body">
                    <p>Research never happens alone. Add your team to collaborate and review projects together.</p>

                    <div className="input-label-group" id="invite-email-container">
                        <label className="input-label" id="email-to-invite">Email address</label>
                        <input id="invite-email-input"  type="text" value={email} onInput={e => setEmail(e.currentTarget.value)}/>
                    </div>

                    <div className="user-type-form" id="invite-form">
                        <h6 id="user-type">User type</h6>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="userType" value="admin" id="admin-radio" onChange={e=>updateUser("admin")}/>
                            <label className="form-check-label">
                              Admin - full access
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="userType" value="member" id="member-radio" onChange={e=>updateUser("member")}/>
                            <label className="form-check-label">
                              Member - full access except inviting users
                            </label>
                        </div>
                        <div id="send-invite-error">{error}</div>
                        <div id="send-invite-success">{success}</div>
                        <div id="invite-btns">
                            <button id="send-invite-btn" type="button" onClick={sendInvite}>Send invite</button>
                            <a id="manage-team" href={`${process.env.REACT_APP_GOLANG_API_URL}/team`}>Manage Team</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}