
export const PreviewCard = (props: {title: string, text: string, image: string, link: string, newTab: boolean, alt: string}) => {
    return (
            <div className="preview-card">
                {props.newTab ? (
                <a href={props.link} className="preview-link" target="_blank" rel="noreferrer"> 
                    <img src={props.image} alt = {props.alt}/>	
                    <h5>{props.title}</h5>
                    <span>{props.text}</span>
                </a>
                ):(
                    <a href={props.link} className="preview-link"> 
                    <img src={props.image} alt = {props.alt}/>	
                    <h5>{props.title}</h5>
                    <span>{props.text}</span>
                </a>  
                )}
            </div>
      );
}