import { PreviewCard } from "../Home/PreviewCard";

export const CongratsCard = () => {
  const practiceData = [
    {
      title: "Create SMS Survey",
      text: "Launch a text survey to understand your community",
      image: require("../../images/sms-project.jpg"),
      link: process.env.REACT_APP_GOLANG_API_URL + "/project-templates",
      alt: "sms-project icon",
    },
    {
      title: "Analyze Survey Data",
      text: "Import responses to understand the top trends",
      image: require("../../images/import-project.jpg"),
      link: process.env.REACT_APP_GOLANG_API_URL + "/project-templates",
      alt: "import-project icon",
    },
  ];

  const learnData = [
    {
      title: "Lessons",
      text: "Learn Equitable Approaches",
      image: require("../../images/courses.jpg"),
      link: "/",
      alt: "course image",
    },
    {
      title: "Events",
      text: "Meet and Exchange wit Experts",
      image: require("../../images/events.jpg"),
      link: "https://cocensus.io/events/",
      alt: "events image",
    },
    {
      title: "Resources",
      text: "E-books and Case Studies",
      image: require("../../images/resources.png"),
      link: "https://cocensus.io/community/",
      alt: "case studies image",
    },
  ];
  

  const handleShareLinkedIn = () => {
    const shareURL =
      "https://www.linkedin.com/sharing/share-offsite?mini=true&url=https://cocensus.io&title=I recommend this course about inclusivity and community engagement for urban researcher &summary=cocensus.io&source=co:census&summary=I recommend this course about inclusivity and community engagement for urban researcher &summary=cocensus.io&source=co:census";
    window.open(
      shareURL,
      "mywin",
      "left=20,top=20,width=500,height=500,toolbar=1,resizable=0"
    );
  };

  return (
    <>
      <div></div>
      <div className="section-card">

      <div id="complete-course-container">
        <h2>Congratulations! &#127881;</h2>
        <h3>You are ready to practice!</h3>
        <p>
          Congratulations on completing this course! Your dedication and
          curiosity have paid off.
          <br />
          This achievement is a testament to your passion and committment to
          learning to better consider the voices of communities.
          <br />
          Keep up the good work and strive for excellance!
        </p>
        <p id="celebrate-networks">Celebrate with your networks:</p>
        <button onClick={handleShareLinkedIn}>Share on LinkedIn</button>
      </div>
      <div id="practice-container">
        <h3 id="practice-header">Start to practice</h3>
        <div id="practice-cards">
          {practiceData.map((card, idx) => {
            return (
              <PreviewCard
                key={idx}
                title={card.title}
                text={card.text}
                image={card.image}
                link={card.link}
                newTab={false}
                alt={card.alt}
              />
            );
          })}
        </div>
      </div>
      <div id="learn-container">
        <h3 id="learn-header">Continue to Learn</h3>
        <div id="learn-cards">
          {learnData.map((card, idx) => {
            return (
              <PreviewCard
                key={idx}
                title={card.title}
                text={card.text}
                image={card.image}
                link={card.link}
                newTab={true}
                alt={card.alt}
              />
            );
          })}
        </div>
      </div>
      </div>
    </>
  );
};
