// import { useAuth0 } from "@auth0/auth0-react";
import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom";
import { TopNav } from "../Components/TopNav";
import { CoursePage } from "./CoursePage/CoursePage";
import { Home } from "./Home/Home";
import { CongratsCard } from "./CoursePage/CongratsCard";
import { useAuth0 } from "@auth0/auth0-react";
import ApiClient from "../Logic/ApiClient";
import { ApiContext } from "../Logic/ApiContextProvider";
import { useState, useEffect } from "react";
import { Userpilot } from "userpilot";
import React from "react";

export const Router = () => {
  const [accesstoken, setAccessToken] = useState("");
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  if (user?.sub) {
Userpilot.identify(user.sub, {
  name: user.name,
  email: user.email,
});
}

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  }

  useEffect(() => {
    async function fetchData() {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    }

    fetchData();
  }, [getAccessTokenSilently]);

  const apiClient = new ApiClient(accesstoken);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div className="app">
          <ApiContext.Provider value={{ apiClient }}>
            <TopNav user={user} logout={logout} />
          </ApiContext.Provider>
          <div className="page">
            <Outlet />
          </div>
        </div>
      ),
      children: [
        { path: "/", element: <Home /> },
        {
          path: "/course/:courseId",
          element: <CoursePage />,
        },
      ],
    },
  ]);

  return (
    <ApiContext.Provider value={{ apiClient }}>
      <RouterProvider router={router} />
    </ApiContext.Provider>
  );
};
