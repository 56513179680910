import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import { Router } from "./Pages/Router";
import { Userpilot } from 'userpilot'

Userpilot.initialize('NX-da6f844b');



const queryClient = new QueryClient();

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";
if (!auth0Domain || !clientId) {
  throw new Error("Missing auth0 domain or client id");
}

function App() {
  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: auth0Audience,
        scope: "read: profile email current_user update:current_user_metadata"
      }}
    >
      <QueryClientProvider client={queryClient}>
          <Router />
      </QueryClientProvider>
    </Auth0Provider>
  );
}

export default App;
